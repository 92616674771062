.rcw-header > .rcw-title {
  color: whitesmoke;
}

.rcw-header > span {
  color: black;
}

.rcw-launcher {
  background-color: #00b5ad;
}

.rcw-message-text > p {
  color: black !important;
}

@media only screen and (max-width: 800px) {
  .rcw-widget-container {
    height: fit-content;
  }
  .rcw-conversation-container .rcw-title {
    padding: 15px 0 15px;
  }

  .rcw-conversation-container {
    height: 0%;
  }

  .rcw-conversation-container.active {
    height: 100vh;
  }
}
