

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}



